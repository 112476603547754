<template>
  <div class="leaderboard container is-fullhd">
    <h1 class="title">Leaderboard</h1>
    <section class="columns">
      <div class="column is-three-quarters">
        <ul>
          <li v-for="(user, i) in users" :key="i" :class="'place-' + (i+1)">
            <template v-if="i >= 3">
              #{{ i + 1 }}
            </template>
            <span v-else class="icon">
              <fa-icon :icon="['fas', 'trophy']" />
            </span>
            <span class="row-body">
              <figure
                class="image user-avatar is-rounded"
                :class="avatarClass(i)"
                :style="{'border': '2px solid #' + user.favouriteColour}">
                <img :src="user.avatar" alt="" class="is-rounded">
              </figure>
              <router-link
                :to="'/user/' + user.username"
                class="user-name">
                {{user.username}}
              </router-link>
              - {{user.experience}} XP
            </span>
          </li>
        </ul>
      </div>

      <Sidebar class="column" />
    </section>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import API from '@/services/api';

export default {
  name: 'Leaderboard',
  components: {
    Sidebar,
  },
  data() {
    return {
      users: [],
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        const { data } = await API.users.listTop();
        this.users = data.users;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    avatarClass(idx) {
      if (idx === 0) return 'is-32x32';
      if (idx < 3) return 'is-24x24';
      return 'is-16x16';
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  position: relative;
  margin-bottom: 5px;

  .row-body {
    position: absolute;
    left: 40px;
  }
}

.user-avatar {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: 4px;

  &.is-24x24 {
    top: 3px;
  }
  &.is-16x16 {
    top: 2px;
  }
}

.place-1 {
  font-size: 2em;

  .icon {
    color: gold;
    position: relative;
    top: 4px;
  }
}
.place-2 {
  font-size: 1.5em;

  .icon {
    color: silver;
    position: relative;
    top: 2px;
  }
}
.place-3 {
  font-size: 1.25em;

  .icon {
    color: #cd7f32;
    position: relative;
    top: 3px;
  }
}
</style>
